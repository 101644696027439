ht ml {
	height: 100%;
}

body {
	height: 100%;

	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body > #root {
	height: 100%;
}

/* We get two <div> elements rendered under the body/root element. We only want to set the height on the first one. */
body > #root > div:first-child {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
	font-size: 1.5rem;
	margin: 0;
	margin-bottom: 0.5rem;
}
h2 {
	font-size: 1.25rem;
	margin: 0;
	margin-bottom: 0.5rem;
}
h3 {
	font-size: 1rem;
	margin: 0;
	margin-bottom: 0.5rem;
}
h4 {
	font-size: 0.75rem;
	margin: 0;
}
h5 {
	font-size: 0.5rem;
	margin: 0;
}

div {
	font-size: 1rem;
}

.resultBox {
	height: calc(100% - 200px);
}

.resultBoxNoLogo {
	height: calc(100% - 140px);
}

.resultLogo {
	max-width: 75%;
	max-height: 60px;
	padding-left: 0.15rem;
	padding-right: 0.15rem;
}

.resultLogoContainer {
	height: 50px;
}

.flexBody100 {
	height: calc(100% - 110px);
}

.flexBody150 {
	height: calc(100% - 140px);
}

.center {
	text-align: center;
}

@media only screen and (max-height: 500px) {
	/* For desktop etc */
	.responsive {
		display: none;
	}
	.resultBox {
		height: calc(100% - 65px);
	}
	.flexBody100 {
		height: calc(100% - 100px);
	}
	.flexBody150 {
		height: calc(100% - 120px);
	}
}

/* @media only screen and (min-height: 500px) {
	.resultLogo {
		max-height: 60px;
	}
}
@media only screen and (min-height: 1100px) {
	.resultLogo {
		max-height: 100px;
	}
} */
